<template>
  <VApp>
    <VDialog
      fullscreen
      persistent
      hide-overlay
      no-click-animation
      transition="fade-transition"
      :value="isTopDialog(componentName)"
    >
      <ModalLayout
        close-to
        title="Клуб ПроДокторов"
        limit-title
        visible-single-divider
        @modal:back="closeAllDialogs"
      >
        <template #titleIcon>
          <img
            src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
            class="ml-1"
            width="24"
            height="24"
            alt="Иконка Процент Клуба"
          >
        </template>
        <ClubInfoModalContent
          class="py-4"
          :pdf-data="pdfData"
        />
      </ModalLayout>
    </VDialog>
  </VApp>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'

import { ClubEventsNames } from 'clubCommonMixins'

import ModalLayout from 'components/mobile/core/ModalLayout.vue'
import ModalManager from 'components/common/mixins/DialogManager'
import ClubInfoModalContent from 'components/mobile/Club/components/dependencies/ClubInfoModal/ClubInfoModalContent.vue'
import ClubInfoModalLogic from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalLogic'
import ClubInfoModalExpansionPanel from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalExpansionPanel'

export default {
  name: 'ClubInfoModal',
  vuetify,
  components: {
    ModalLayout,
    ClubInfoModalContent,
  },
  mixins: [
    ModalManager,
    ClubEventsNames,
    ClubInfoModalLogic,
    ClubInfoModalExpansionPanel,
  ],
  mounted() {
    window.addEventListener(this.eventClubSelectInfoModal, this.handleSelectInfoModal)
  },
}
</script>

<style>
#app .v-application--wrap {
  min-height: auto;
}
</style>
