<template>
  <div>
    <div class="club-info-modal-description px-2">
      <div class="ui-text ui-text_h6 ui-kit-color-text mb-2">
        Что даёт клуб
      </div>
      <div class="ui-text ui-text_body-1 ui-kit-color-text">
        Возможность получить скидку 30% на консультацию у врача.
      </div>
      <div class="ui-text ui-text_h6 ui-kit-color-text mt-8 mb-2">
        Как получить скидку
      </div>
      <div class="b-club-list ui-text ui-text_body-1 ui-kit-color-text">
        <div class="b-club-list__item">
          Оплачиваете на сайте ПроДокторов 50% стоимости консультации по цене со скидкой
        </div>
        <div class="b-club-list__item">
          В день визита оплачиваете в клинике оставшиеся 50%
        </div>
      </div>
      <div class="ui-text ui-text_h6 ui-kit-color-text mt-8 mb-2">
        Как вступить в клуб
      </div>
      <div class="ui-text ui-text_body-1 ui-kit-color-text mb-4">
        Просто воспользуйтесь клубной ценой и вы станете участником клуба на 6 месяцев.
      </div>
      <div class="ui-text ui-text_body-1 ui-kit-color-text">
        После доступ к клубным ценам на 6 месяцев будет стоить 99 ₽.
      </div>
    </div>
    <div class="ui-text ui-text_h6 ui-kit-color-text mt-10 mb-4 px-2 mt-8">
      Вопросы и ответы
    </div>
    <VExpansionPanels
      class="px-2"
      multiple
    >
      <VExpansionPanel
        v-for="(item, index) in expansionPanel"
        :key="`${componentName}ExpansionItem${index}`"
      >
        <VExpansionPanelHeader
          :expand-icon="vuetifyIcons.arrowDown"
        >
          <span class="ui-text ui-text_body-1">{{ item.title }}</span>
        </VExpansionPanelHeader>
        <VExpansionPanelContent class="ui-text ui-text_body-1">
          {{ item.description }}
        </VExpansionPanelContent>
      </VExpansionPanel>
    </VExpansionPanels>
    <div class="mt-10 px-2">
      <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary text-center">
        © 2011 — {{ nowYear }}, ООО «МЕДРОКЕТ»‎
      </div>
      <div class="d-block ui-text ui-text_body-2 ui-kit-color-text-secondary mt-2 text-center">
        Оплачивая, вы соглашаетесь
        с&nbsp;<a
          :href="pdfData.coupon"
          rel="noopener noreferrer"
          target="_blank"
          class="ui-kit-color-text-secondary"
        >офертой</a>
        и&nbsp;<a
          :href="pdfData.participation"
          rel="noopener noreferrer"
          target="_blank"
          class="ui-kit-color-text-secondary"
        >правилами клуба</a>
        <!-- eslint-enable vue/no-template-target-blank -->
      </div>
    </div>
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import ClubInfoModalLogic from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalLogic'
import ClubInfoModalExpansionPanel from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalExpansionPanel'

export default {
  name: 'ClubInfoModalContent',
  vuetify,
  mixins: [
    ClubInfoModalLogic,
    ClubInfoModalExpansionPanel,
  ],
}
</script>
