/* eslint-disable */
$(function () {
    let lineNum = 4;

    function makeEllip ($el) {
        $el.html($el.text());
        const $wrapper = $('<span class="' + 'b-feed__ellip-wrapper' + '" />').text($el.text());

        let temp = $el.data('lines');
        if (temp) {
            lineNum = parseInt(temp);
        }

        let currentOffset = null;
        let currLine = 0;
        let startEllipAt = 0;

        $el.empty().append($wrapper);

        const words = $.trim($wrapper.text()).split(/\s+/);
        const span = '<span style="white-space: nowrap;">';

        $wrapper.html(span + words.join('</span> ' + span) + '</span>');

        $wrapper.find('span').each(function (i, word) {
            const $word = $(word);
            const top = $word.position().top;

            if (top !== currentOffset) {
                currentOffset = top;
                currLine += 1;
            }

            if (currLine === lineNum) {
                startEllipAt = i;
                return false;
            }
        });

        if (currLine >= lineNum) {
            words[startEllipAt] = '<span class="' + 'b-feed__ellip-line">' + words[startEllipAt];

            words.push('</span>');
        }

        $wrapper.html(words.join(' '));
    }

    const $el = $('.b-feed__link');
    const $feedItem = $el.closest('.b-feed__item');

    $el.each(function (i, elem) {
        const $elem = $(elem);
        makeEllip($elem);
        if ($elem.parents('.b-owl-slider').length > 0) {
            $(window).on('initialized.owl.carousel', function () {
                makeEllip($elem);
            });

            $(window).on('resized.owl.carousel', function () {
                makeEllip($elem);
            });
        } else {
            const titleWidth = $feedItem.innerWidth() - $feedItem.find('.b-feed__number').innerWidth();
            $feedItem.find('.b-feed__title').each(function (i, el) {
                $(el).innerWidth(titleWidth);
            });
            makeEllip($elem);

            $(window).on('resize', function (event) {
                const titleWidth = $feedItem.innerWidth() - $feedItem.find('.b-feed__number').innerWidth();
                $feedItem.find('.b-feed__title').each(function (i, el) {
                    $(el).innerWidth(titleWidth);
                });
                makeEllip($elem);
            });
        }
    });

    const $message = $('.b-feed__expand-message');
    const messageHidden = $('.b-feed__expand-message').html();
    const messageExpanded = "Скрыть";

    let hidden = true;

    $('.b-feed__expand').on('click', function (event) {
        $('.b-feed__item_hidden').each(function (i, el) {
            $(el).toggle();
        });
        hidden = !hidden;
        if (hidden) {
            $message.html(messageHidden);
        } else {
            $message.html(messageExpanded);
        }

        $('.b-feed__link').each(function (i, el) { makeEllip($(el)) });
    });
});
