/* eslint-disable */
$(document).ready(() => {
  const list_link = $('.list__link')
  const list_title_full_width_1 = $('#list__title_full-width_1')
  const list_title_full_width_2 = $('#list__title_full-width_2')
  const list__wrapper_1 = $('#list__wrapper_1')
  const list__wrapper_2 = $('#list__wrapper_2')

  list_link.click(function (event) {
    const now_link = $(this)

    if (now_link.not('.list__link-classic')) {
      return
    }

    now_link.not('.list__link-classic').addClass('list__link_click')
    now_link.addClass('list__link_shadow')
    setTimeout(() => {
      now_link.removeClass('list__link_click')
    }, 300)
  })
  list_title_full_width_1.click(() => {
    list__wrapper_1.toggle()

    if (list_title_full_width_1.hasClass('list__title_full-width-d')) {
      list_title_full_width_1.removeClass('list__title_full-width-d')
      list_title_full_width_1.addClass('list__title_full-width-r')
    } else {
      list_title_full_width_1.removeClass('list__title_full-width-r')
      list_title_full_width_1.addClass('list__title_full-width-d')
    }
  })
  list_title_full_width_2.click(() => {
    list__wrapper_2.toggle()

    if (list_title_full_width_2.hasClass('list__title_full-width-d')) {
      list_title_full_width_2.removeClass('list__title_full-width-d')
      list_title_full_width_2.addClass('list__title_full-width-r')
    } else {
      list_title_full_width_2.removeClass('list__title_full-width-r')
      list_title_full_width_2.addClass('list__title_full-width-d')
    }
  })

  const list_link_rates = $('.list__link-rates')

  list_link_rates.click(function () {
    location.href = $(this).data('href')
  })
  $(document).on('click', '.list__link_shine-on', function (event) {
    event.preventDefault()

    const element = $(this)

    element.addClass('list__link_shine')
    setTimeout(() => {
      element.removeClass('list__link_shine')
    }, 1500)
    setTimeout(() => {
      location.href = element.attr('href')
    }, 1)
    // для коректной работы подсветки в IPhone... без setTimeout изменение location игнорировало/или не успевало выполнять код перед собой
  })
  $(document).on('click', '.list__doctors-link-shine', function (event) {
    event.preventDefault()// функция была изменена для корректоной работы подсветки на IPhone.

    const element = $(this)
    const count_elem = element.find('span.list__doctors-link-disc')

    if (count_elem[0]) {
      count_elem.addClass('list__doctors-link-disc_white')
    }

    element.addClass('list__doctors-link_shine-on')
    setTimeout(() => {
      element.removeClass('list__doctors-link_shine-on')

      if (count_elem[0]) {
        count_elem.removeClass('list__doctors-link-disc_white')
      }
    }, 1500)
    setTimeout(() => {
      location.href = element.attr('href')
      // простой вызов location вне функции не обрабатывает код идущий перед собой( скорее всего ), поэтому используется setTimeout
    }, 1)
  })
})
