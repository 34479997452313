var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    [
      _c(
        "VDialog",
        {
          attrs: {
            fullscreen: "",
            persistent: "",
            "hide-overlay": "",
            "no-click-animation": "",
            transition: "fade-transition",
            value: _vm.isTopDialog(_vm.componentName),
          },
        },
        [
          _c(
            "ModalLayout",
            {
              attrs: {
                "close-to": "",
                title: "Клуб ПроДокторов",
                "limit-title": "",
                "visible-single-divider": "",
              },
              on: { "modal:back": _vm.closeAllDialogs },
              scopedSlots: _vm._u([
                {
                  key: "titleIcon",
                  fn: function () {
                    return [
                      _c("img", {
                        staticClass: "ml-1",
                        attrs: {
                          src: "/static/_v1/pd/icons/ui-kit/club/club-percent.svg",
                          width: "24",
                          height: "24",
                          alt: "Иконка Процент Клуба",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("ClubInfoModalContent", {
                staticClass: "py-4",
                attrs: { "pdf-data": _vm.pdfData },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }