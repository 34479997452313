import Vue from 'components'
import { MOUNT_SELECTOR, DATA_PDF_COUPON, DATA_PDF_PARTICIPATION } from 'components/mobile/Club/components/dependencies/ClubInfoModal/constants'
import ClubInfoModal from 'components/mobile/Club/components/dependencies/ClubInfoModal/ClubInfoModal.vue'

const mountNode = document.querySelector(MOUNT_SELECTOR)

if (mountNode) {
  const dataCouponPDF = mountNode.getAttribute(DATA_PDF_COUPON)
  const dataParticipationPDF = mountNode.getAttribute(DATA_PDF_PARTICIPATION)

  if (dataCouponPDF && dataParticipationPDF) {
    new Vue({
      el: mountNode,
      render: h => h(ClubInfoModal, {
        props: {
          pdfData: {
            coupon: dataCouponPDF,
            participation: dataParticipationPDF,
          },
        },
      }),
    })
  }
}
