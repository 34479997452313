/* eslint-disable */
$(function () {
    const lineNum = 3;
    function makeEllip (i, el) {
        const $el = $(el);
        const $wrapper = $('<span class="' + 'b-top-card__ellip-wrapper' + '" />').text($el.text());

        let currentOffset = null;
        let currLine = 0;
        let startEllipAt = 0;

        $el.empty().append($wrapper);

        const words = $.trim($wrapper.text()).split(/\s+/);
        const span = '<span style="white-space: nowrap;">';

        $wrapper.html(span + words.join('</span> ' + span) + '</span>');

        $wrapper.find('span').each(function (i, word) {
            const $word = $(word);
            const top = $word.position().top;

            if (top !== currentOffset) {
                currentOffset = top;
                currLine += 1;
            }
            if (currLine === lineNum) {
                startEllipAt = i;
                return false;
            }
        });

        if (currLine >= lineNum) {
            // words[startEllipAt] = '<span class="' + 'b-top-card__ellip-line' + '">' + words[startEllipAt];

            const width = $('.b-top-card').width() - $('.b-top-card__part-left').width();
            words[startEllipAt] = '<span class="' + 'b-top-card__ellip-line' + '" style="width: ' + width + 'px">' + words[startEllipAt];

            words.push('</span>');
        }

        $wrapper.html(words.join(' '));
    }
    $('.b-top-card__summary').each(makeEllip);
    $(window).on('resize', function () {
        $('.b-top-card__summary').each(makeEllip);
    });
});
